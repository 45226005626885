import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';

import { useFetchRequiredActions } from '@unitoio/mosaic';

import { getContainerById, getFeatureFlagValue } from '~/reducers';
import * as featureTypes from '~/consts/features';
import * as linkTypes from '~/consts/link';

export function useSetRequiredActions(side) {
  const [providerIdentityId, containerId, itemType, linkState, currentSideActions] = useWatch({
    name: [`${side}.providerIdentityId`, `${side}.containerId`, `${side}.itemType`, 'state', `rules.${side}.actions`],
  });
  const { setValue } = useFormContext();

  const usesModernActions = useSelector((state) => getFeatureFlagValue(state, featureTypes.FEATURES.MODERN_ACTIONS));
  const container = useSelector((state) => getContainerById(state, providerIdentityId, containerId));
  const containerPath = container?.get('path');

  const { isFetchingFields, requiredActions } = useFetchRequiredActions({
    credentialId: providerIdentityId,
    containerPath,
    itemType,
  });

  const updateActions = useCallback(
    (values) => {
      setValue(
        `rules.${side}.actions`,
        values.map((action) => ({ ...action, trigger: 'start' })),
        { shouldDirty: true },
      );
    },
    [setValue, side],
  );

  const isDraft = linkState === linkTypes.LINK_STATES.DRAFT;

  const shouldSetRequiredActions =
    usesModernActions &&
    !isFetchingFields &&
    isDraft &&
    requiredActions.length &&
    !requiredActions.every((requiredAction) =>
      currentSideActions.some((action) => action.fieldName === requiredAction.fieldName),
    );

  useEffect(() => {
    if (shouldSetRequiredActions) {
      updateActions(requiredActions);
    }
  }, [requiredActions, shouldSetRequiredActions, updateActions]);

  return { isFetchingFields };
}
