import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

import { Button, Flex, Skeleton, Space, tokens } from '@unitoio/mosaic';
import * as linkTypes from '~/consts/link';
import { trackEvent } from '~/actions/tracking';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { PageContainer } from '../PageContainer/PageContainer';
import { ModernRulesHeader } from './ModernRulesHeader';
import { ModernRuleSide } from './ModernRuleSide';
import { statuses } from '../Rules/consts';
import * as formUtils from '../../utils/form';
import { ManageDuplicates } from '../../components/ManageDuplicates';
import { useInitializeLegacyDefaultValues } from '../../hooks/useInitializeLegacyDefaultValues';
import { useSetRequiredActions } from './hooks/useSetRequiredActions';

// TODO: the loading state is tied to the flow builder master hook, can't extract it easily so still drilling it down
export const ModernRules = ({ loadingState }) => {
  const {
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const isLoading = [statuses.INITIAL, statuses.LOADING, statuses.STANDBY].includes(loadingState);
  const currentLinkState = useWatch({ name: 'state' });
  const isDraftFlow = currentLinkState === linkTypes.LINK_STATES.DRAFT;
  const hasErrors = errors?.A?.actions || errors?.B?.actions;

  const handleOnSubmitClick = async () => {
    trackEvent(trackingTypes.SUBMIT);
    await handleSubmit({ redirectPage: routes.FLOW_BUILDER_PAGES.MAPPINGS });
  };

  // Initialize the default values for the legacy defaultValues
  // This will be scrapped when modern defaultValues are fully supported
  const { initializeFieldsLoadingState } = useInitializeLegacyDefaultValues({
    loadingState,
    shouldInitializeSpecialRules: false,
  });

  // Note: ideally we'd move the useSetRequiredActions hooks to useFlowBuilderGetForm
  // but because of the current amount of re-renders at the flow level,
  // we're keeping it in ModernRules for now.
  const { isFetchingFields: isFetchingFieldsA } = useSetRequiredActions('A');
  const { isFetchingFields: isFetchingFieldsB } = useSetRequiredActions('B');
  const isFetchingFields = isFetchingFieldsA || isFetchingFieldsB;

  return (
    <PageContainer>
      <ModernRulesHeader />

      <Skeleton loading={isLoading || isFetchingFields}>
        <Space style={{ marginBottom: tokens.spacing.s4 }}>
          <ManageDuplicates />
        </Space>
        <ModernRuleSide
          side="A"
          loadingState={loadingState}
          initializeFieldsLoadingState={initializeFieldsLoadingState}
        />
        <ModernRuleSide
          side="B"
          loadingState={loadingState}
          initializeFieldsLoadingState={initializeFieldsLoadingState}
        />
      </Skeleton>
      {isDraftFlow && (
        <Flex justify="flex-end">
          <Button
            disabled={hasErrors}
            type="submit"
            loading={loadingState === formUtils.loadingStates.SAVING}
            onClick={handleOnSubmitClick}
          >
            Confirm
          </Button>
        </Flex>
      )}
    </PageContainer>
  );
};

ModernRules.propTypes = {
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
};
